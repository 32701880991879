import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const query = graphql`
    query {
        diploma: file(relativePath: { eq: "diploma-kindertekeningen.png" }) {
            childImageSharp {
                fluid(maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        logoKindertekening: file(relativePath: { eq: "logo-kindertekeningen.png" }) {
            childImageSharp {
                fluid(maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

const ChildDrawings = ({ data }) => (
  <Layout pageInfo={{
    pageName: "kindertekeningen",
    pageTitle: "Analyse Kindertekeningen",
    bannerTitle: "website-header-04.png",
  }}>
    <Seo title="Analyse Kindertekeningen" />

    <Container>
      <section className="container" id="child-drawings">
        <Row>
          <Col>
            <h2>Hoe bijzonder zijn tekeningen?</h2>
            <div className="block">
              <p>Als ouder wil je een kind zo goed mogelijk begrijpen. Maar kinderen kunnen niet altijd alles vertellen,
                ze zijn daar simpelweg nog te jong voor of ze kunnen niet goed onder woorden brengen wat hen bezighoudt.
                Ook willen ze het soms gewoon helemaal niet vertellen of zij durven dit niet. In meer extreme gevallen
                mogen zij niet alles vertellen wat er in hen omgaat. En dat terwijl je zeker weet dat er iets bij het
                kind speelt! Dit komt vaker voor dan je denkt. En wat dan? Of misschien weet je eigenlijk heel goed
                waardoor het kind zich ineens anders gedraagt, er is bijvoorbeeld iets heftigs gebeurd of jullie zitten
                in een moeilijke periode. Ook dan wil je waarschijnlijk graag weten wat er in het kind omgaat. Nou, dat
                kan!</p>

              <p>Naast de gesproken taal bestaat er ook een beeldentaal van tekeningen. Kinderen tekenen overal, niet
                alleen met potlood of stift op papier, maar denk ook aan geverfde kunstwerken of een kunstwerk gemaakt
                door middel van tekenen in scheerschuim. In deze tekeningen kunnen kinderen hun gevoelens, emoties en
                gebeurtenissen uiten zonder dat het invloed heeft op de echte wereld. Op die manier kunnen spanningen
                worden ontladen en dingen een plekje worden gegeven. Deze tekeningen bevatten dus een schat aan
                informatie, zelfs de paar krassen van een heel jong kind: zij laten onbewust iets van zichzelf zien en
                vertellen in hun tekeningen zonder woorden wat hen bezighoudt of hoe zij zich voelen. Door middel van
                een tekening kun je in de belevingswereld van het kind stappen. Dit gaat het beste bij tekeningen die
                zijn gemaakt zonder opdracht, dus vrije tekeningen waarbij geen ‘hints’ aan het kind zijn gegeven over
                wat het zou kunnen tekenen of hoe.
              </p>

              <p>
                In tekeningen is dus onder meer zichtbaar:

                <ul>
                  <li>het verwerken van emoties en gebeurtenissen;</li>
                  <li>het spiegelen van de omgeving;</li>
                  <li>wat een kind bezighoudt;</li>
                  <li>het zelfbeeld;</li>
                  <li>hoe het gezin of de omgeving wordt ervaren;</li>
                  <li>de ontwikkeling van een kind, etc.</li>
                </ul>

                Wanneer je weet wat er speelt, kun je het kind bieden wat hij of zij écht nodig heeft en van je vraagt.
                Je kunt je beter met het kind verbinden, wat een positieve onderlinge band versterkt.

              </p>
            </div>

            <h2>Hoe werkt het?</h2>
            <div className="block">
              <p>Je stuurt een (duidelijke foto van een) tekening op. Om de tekening goed te kunnen analyseren is het
                goed bepaalde informatie daarbij mee te sturen. Allereerst de geboortedatum en het geslacht van de
                tekenaar, wanneer de tekening is gemaakt en of de tekenaar keuze had uit verschillende materialen (zowel
                om mee te tekenen als om op te tekenen). Daarnaast de reden waarom ervoor wordt gekozen een tekening te
                laten analyseren, een korte beschrijving van de persoonlijke situatie van de tekenaar (gezinssituatie en
                school) en ten slotte eventuele bijzonderheden.
                Daarna wordt de tekening geanalyseerd. Ik kijk daarbij naar een heleboel dingen, zoals bijvoorbeeld
                figuren en kleuren, maar ook plaatsing op het blad en symboliek. Uiteindelijk worden alle bevindingen
                samengevoegd in een conclusie. </p>

              <p>Er bestaan dan verschillende mogelijkheden. Allereerst kan de analyse per e-mail worden verstuurd of
                per post worden toegezonden zodat deze rustig kan worden doorgelezen. Er kan ook voor worden gekozen om
                een afspraak met mij te maken (in de praktijk of via skype) om de analyse samen te bespreken. Er kunnen
                dan vragen over de analyse worden gesteld en ik kan - indien nodig - tips en adviezen geven naar
                aanleiding van de analyse. Natuurlijk kan ook worden gekozen voor een combinatie van beide en ten slotte
                is het mogelijk, wanneer hiertoe aanleiding wordt gezien en altijd in gezamenlijk overleg, een
                coachingstraject worden opgestart om uw kind (en eventueel uw gezin) te begeleiden.</p>
            </div>

            <h2>Tarieven</h2>
            <div className="block">
              <p>Een schriftelijke analyse, die per e-mail kan worden verstuurd of per post kan worden toegezonden zodat
                deze rustig kan worden doorgelezen, is mogelijk voor €39,95.
                Wanneer de voorkeur uitgaat naar een gezamenlijke bespreking van de analyse (in de praktijk of via
                skype), waarbij vragen over de analyse kunnen worden gesteld en ik - indien nodig - tips en adviezen kan
                geven naar aanleiding van de analyse, is dit mogelijk voor €59,95.
                Ten slotte is het mogelijk een afspraak te maken met mij voor een gezamenlijke bespreking van de analyse
                (in de praktijk of via skype), waarbij vragen over de analyse kunnen worden gesteld en ik
                - indien nodig - tips en adviezen kan geven naar aanleiding van de analyse, waarna de gehele uitgewerkte
                analyse (inclusief een korte samenvatting van het gesprek) per e-mail wordt verstuurd of per post zal
                worden toegezonden zodat deze nog kan worden nagelezen, voor €69,95.
              </p>
              <p>
                <Row>
                  <Col> Dienst </Col>
                  <Col> Prijs </Col>
                </Row>
                <Row>
                  <Col> Schriftelijke analyse </Col>
                  <Col> € 39,95 </Col>
                </Row>
                <Row>
                  <Col> Bespreking analyse </Col>
                  <Col> € 59,95 </Col>
                </Row>
                <Row>
                  <Col> Combinatie schriftelijke en bespreking analyse </Col>
                  <Col> € 69,95 </Col>
                </Row>
              </p>
            </div>

            <h2>Overige informatie</h2>
            <div className="block">
              <p>Het bedrag van het soort analyse dat u kiest dient voorafgaand aan de analyse, bij toezending van de
                tekening en de daarbij behorende informatie, te worden voldaan.
                Mijn streven is om de analyse zo snel mogelijk, in ieder geval binnen 10 dagen, uit te werken.</p>
              <p>De genoemde prijzen zijn inclusief 9% BTW.</p>
              <p>Voor de overige voorwaarden: zie de <a href="https://wonderewereldvantekeningen.nl/algemene-voorwaarden">Algemene Voorwaarden</a>.</p>
            </div>

          </Col>
        </Row>


        <Row>
          <Col xs={{span: 12}} md={{ span: 6 }}>
            <Img className="image-padding" fluid={data.diploma.childImageSharp.fluid} alt="Diploma Kindertekeningen" />
          </Col>
          <Col xs={{span: 12}} md={{ span: 6 }}>
            <Img className="image-padding" fluid={data.logoKindertekening.childImageSharp.fluid} alt="Logo - Wondere Wereld van Tekeningen" />
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default ChildDrawings

